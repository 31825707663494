// Plugin import
// import crosswords from './crosswords'
// import rometransformed from './rometransformed'
//import chimera from './chimera'

// Add here plugins you want activate
var list = [
  // chimera
  // crosswords,
  // rometransformed
]
var plugins = { models: [], routes: [], menu: [], dashboard: [], messages: {} }

list.forEach(function(plugin) {
  plugins.routes = plugins.routes.concat(plugin.routes)
  plugins.menu = plugins.menu.concat(plugin.menu)
  plugins.dashboard = plugins.dashboard.concat(plugin.dashboard)
  plugins.models = plugins.models.concat(plugin.model)
  plugins.messages[plugin.name] = plugin.messages
})

export default plugins