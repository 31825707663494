<template>
  <div id="app">
    <div class="bg-black font-montserrat" v-if="isAuthenticated">
      <router-view />
      <modals-container />
    </div>
    
    
    <!--<div :class="'grid bg-gray-100 min-h-screen w-full h-full '+ (show ? 'grid-cols-5' : 'grid-cols-15')">
      <NavBar classes="hidden md:block col-span-1" @showNav="showNav"/>
      <div :class="show ? 'col-span-5 md:col-span-4' : 'col-span-15 md:col-span-14'" @click="hideUserMenu">
        <div class="text-xs text-gray-700">
          <router-view />
          <modals-container />
        </div>
      </div>
    </div>-->

  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'

import auth from '@/services/auth'
import Api from '@/components/Api'

//import Template from '@/layouts/Template.vue'
// import NavBar from '@/components/NavBar.vue'

export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'App',
    // all titles will be injected into this template
    titleTemplate: '%s | '+ (process.env.VUE_APP_NAME || 'ReSole')
  },
  mixins: [
    Api
  ],
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      user: 'auth/user',
      locale: 'app/locale',
      showUserMenu: 'app/showUserMenu'
    }),
  },
  data: () => ({
    show: true,
  }),
  async created() {
    const vm = this
    vm.loading = true
    if(!vm.isAuthenticated){
      try {
        if(process.env.VUE_APP_API_USERNAME && process.env.VUE_APP_API_PASSWORD){
          await auth.login({
            email: process.env.VUE_APP_API_USERNAME, 
            password: process.env.VUE_APP_API_PASSWORD 
          }).then(response => {
            console.log('[login response]', response)
            if(response.success) {
              //vm.$router.push({name: 'home'})
            }else{
              vm.error = response.message || response.data
              vm.status = response.data.status || null
              vm.showError = true
            }
          })
        } else {
          alert('NO API CREDENTIALS')
        }
      } catch (error){
        vm.error = error
        vm.showError = true
        vm.submitting = false
      }
    }
  },
  methods: {
    hideUserMenu(){
      this.$store.dispatch(`app/showUserMenu`, {showUserMenu: false})
    },
    showNav(show){
      this.show = show
    }
  },
}
</script>