import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

import plugins from '@/plugins'

const messages = {
  en: {
    global: {
      menu: 'Menu',
      more: 'Find out more...',
      back: 'Go Back',
      hello: 'Hello, ',
      of: 'of',
      select: 'Select...',
      no_results: 'No results',
      select_one: `Select one`,
      search_everywhere: `Search everywhere`,
      legend: 'Legenda',
      hello_welcome: ' and welcome'
    },
    tips: {
      save_before: 'Save before',
      remember_update: 'Remember to save Card.',
      remember_save: 'Card will be automatically saved.'
    },
    actions: {
      label: 'Actions',
      new: 'New',
      edit: 'Edit',
      delete: 'Delete',
      save: 'Save',
      update: 'Update',
      cancel: 'Cancel',
      confirm: 'Confirm',
      signin: 'Sign In',
      import: 'Import',
      import_media: 'Media',
      import_cards: 'Cards',
      upload_files: 'Upload media',
      export_shapefile: 'Export Shapefile',
      select: (ctx) => `Select ${ctx.named('name')}`,
      select_one: `Select`,
      export_cards: 'Export',
      export: 'Export',
      group: 'Azioni di gruppo',
      editor: {
        list: 'list',
      },
      search: 'Search',
      download_import_model: 'Download model',
      import_shapefile: `Import Shapefile`,
      register: 'Register',
      password_reset: 'Reset password',
      resend_verification: 'Invia nuovamente verifica della email'
    },
    components: {
      crud: {
        card_owned_by: 'Card owned by',
        card_owner_info: 'For info and report, contact archive managers',
        website_card_url: 'Show Card in website'
      },
      lemmatizer: {
        associate: 'Associate',
        update: 'Update',
        new: 'Add a new lemma',
        no_lemma: 'No lemma associated',
        save_before: 'Save before'
      },
      chart: {
        cards: 'Cards'
      },
      map: {
        want_add_location: 'Do you want add coordinates for this location?',
        add_location: 'Add coordinates',
        tip_double_click: 'Double click on map to move the marker or drag in desired position',
        place_coordinates: 'Coordinates in this location',
        popup_default: 'This point define current location position.',
        remove_location: 'Remove coordinates',
        no_data: 'No geografic data to show'
      },
      geometry: {
        add_point: 'Add point',
        create: 'Create',
        remove: 'Remove',
        want_create: (ctx) => `Do you want create a ${ctx.named('type')} geometry for this card?`,
        last_point: `The last point must coincide with first to close polygon`,
        digitize: `To digitize a geometry click on toolbar on top-right of map on the avaiables geometry type and click on map each vertex needed. Remember to click on "Save" to close geometry digitizing. Click on edit icon to modify position of each vertex and create new ones. Click on delete to delete geometry.`
      },
      file_manager: {
        no_content: 'No associated content.',
        is_cover: 'Image is cover',
        set_as_cover: 'Set as cover'
      },
      threed_visualizer: {
        scene_elements: '3D Elements',
      }
    },
    modals: {
      delete: {
        title: `Vuoi procedere con l'eliminazione della scheda?`,
        content: `L'operazione una volta eseguita non può essere annullata`,
        confirmed: (ctx) => `${ctx.named('name')} eliminato`,
      },
      update: {
        confirmed: 'Aggiornato!'
      },
      save: {
        confirmed: 'Salvato!'
      },
      download: {
        confirmed: 'Scaricato!'
      },
      importer: {
        title: (ctx) => `Import data for ${ctx.named('name')} by file`,
        media_title: (ctx) => `Import media for ${ctx.named('name')}`,
        content: `Upload a file from your PC drag just below and click on "Upload". After upload completed, click on "Import" to execute data importing.`,
        media_content: `Upload media from your PC drag just below and then click on "Upload". After upload complete, click on "Import" to exectute media importing and associations with cards.<br/>For automatic association each file must have "code" field of Card as filename.`,
        confirmed: `Importing started. It can takes some minutes.`,
        warnings: (ctx) => `<strong>NOTE</strong>: Can be uploaded max <strong>${ctx.named('max_file_uploads')} files</strong> at once, and with a maxmimum weight of <strong>${ctx.named('post_max_size')}B</strong> for each one.`,
        uploaded: `Media successfully uploaded and ready to elaboration.`,
        upload_only: `Upload only: `,
        type_not_allowed: 'Questo tipo di file non è supportato',
        shapefile_title: `Shapefile Import`,
        shapefile_content: `Import GIS vectorial data from Shapefile`,
      },
      exporter: {
        title: (ctx) => `Exporting ${ctx.named('name')} data`,
        content: `Export card in Excel or PDF format. In order to export just some cards, apply filters before and than reopen this modal to start exporting.`,
        confirmed: `Exporting started. It can takes some minutes. Will be send an email with attachment request.`,
        shapefile_content: `Export geometries in ESRI Shapefile format. Select a geometry type and a category of data. Then click on Export button.`,
      },
      coverize: {
        confirmed: 'Image assigned as cover.'
      },
      confirm: {
        title: 'Confirm',
        content: `Do you want confirm operation?`
      },
      clone: {
        title: 'Card cloning',
        content: 'Do you want make a copy of this Card?',
        confirmed: 'Card cloned successfully'
      },
      error: {
        import: 'Import failed',
        upload: 'File upload failed',
        upload_file_type: 'File Format not recognized',
        missing_archive: 'Missing archive',
        related: 'Error occurred when creating related card',
        missing_category: 'Please select a category',
        missing_geometry: 'Please select a geometry',
        lemma_attach: `Error occurred when attaching lemma`,
        lemma_update: 'Error occurred when updating lemma',
        export: `Error occuring when file exporting`,
        user_registration: `Error occuring when registrating user`,
      }
    },
    menu: {
      login: 'Login',
      logout: 'Logout',
      register: 'Register',
      home: 'Dashboard',
      projects: 'Project',
      users: 'Users',
      cards: 'Cards',
      archives: 'Archives',
      locations: 'Locations',
      classifications: 'Classifications',
      tags: 'Tags',
      mapviewer: 'Map',
      medias: 'Media',
      profile: 'Profile',
      logs: 'Logs',
      tenants: 'Tenants'
    },
    pagination: {
      results: 'Results',
      prev: 'Previous',
      next: 'Next',
      last: 'Last page'
    },
    profile: {
      confirmed: 'Confirmed'
    },
    roles: {
      admin: 'Admin',
      archive_manager: 'Archive Manager',
      card_manager: 'Card compiler',
      tenant_manager: 'Tenant Manager',
      viewer: 'Viewer'
    },
    home: {
      tips: 'Tips!'
    },
    views: {
      dashboard: {
        title: 'Welcome!',
        subtitle: '',
        single: '',
        fields: {}
      },
      unauthorized: {
        title: 'You cannot access to this page',
        subtitle: `You doesn't have permission to access this section`,
        single: '',
        fields: {}
      },
      login: {
        title: 'Sign In',
        subtitle: 'Insert your credentials',
        single: '',
        fields: {
          name: 'Name',
          email: 'Email',
          password: 'Password',
          confirm_password: 'Confirm password',
          institution: 'Institution'
        },
        missing_email: 'Insert your email',
        reset_password: 'An email with link to reset your password has been sent.'
      },
      register: {
        title: 'Register',
        lost_password: 'Lost Password',
        request:  `Your request was successful.<br/>You'll recieve an email with a verification link. Click on that link to verify your account and you'll be able to access to platform`,
        resend_verification: `A new email verification was send to your mailbox.`,
        email_already_verified: 'Your email has been already verified. Click on following button to login',
        email_verified: 'Your email has been verified. Click on following button to login',
        email_to_verify: 'Your email must be verified.'
      },
      lostpassword: {
        title: 'Reset your password',
        missing_data: 'To reset your password go to signin paga and click on "Lost password"',
        password_changed: 'User password was changed successfully. Please signin.'
      },
      projects: {
        title: 'Projects',
        subtitle: 'Manage Projects',
        single: 'Project',
        fields: {
          name: 'Name',
          introduction: 'Introduction',
          location: 'Location',
          cost: 'Cost (in €)'
        }
      },
      users: {
        title: 'Users',
        subtitle: 'Manage Users',
        single: 'User',
        fields: {
          name: 'Name',
          email: 'Email',
          roles: 'Roles',
          password: 'Password',
          confirm_password: 'Confirm password',
          cards_count: 'Cards',
          email_verified_at: 'Verified at',
          institution: 'Institution',
          tenants: 'Tenants',
          favourites: 'Favourites',
          verified: 'Verified'
        },
        profile: {
          title: 'Your profile'
        }
      },
      cards: {
        title: 'Cards',
        subtitle: 'Manage Cards',
        single: 'Card',
        fields: {
          name: 'Name',
          code: 'Code',
          archive_id: 'Archive',
          archive: 'Archive',
          user_id: 'Compiler',
          user: 'Compiler',
          is_visible: 'Visible',
          is_published: 'Public',
          location: 'Location',
          location_id: 'Location',
          tags: 'Tags',
          card_separator: 'Base card data',
          lemma_separator: 'Card associated lemmas',
        },
        files: {
          types: {
            document: 'Documentation',
            raster: 'Raster',
            photo: 'Photo',
            image: 'Picture',
            threed_model: '3D Model', 
            threed_texture: '3D Texture', 
            spheric_picture: '360° Picture'
          },
          fields: {
            description: 'File description',
            license: `Use License`,
            reference: 'References',
            crs: 'CRS',
            ne_lat: 'Latitude NE',
            ne_lng: 'Longitude NE',
            sw_lat: 'Latitude SW',
            sw_lng: 'Longitude SW',
            file_id: 'Associated file',
            elevation: 'Elevation',
            file_select: 'Select a file to associate'
          }
        },
      },
      archives: {
        title: 'Archives',
        subtitle: 'Manage Archives',
        single: 'Archive',
        fields: {
          name: 'Name',
          code: 'Code',
          cards_count: 'Cards',
          users: 'Users',
          tenants: 'Tenants'
        },
        select: `Select archive`
      },
      tenants: {
        title: 'Tenants',
        subtitle: 'Manage Tenants',
        single: 'Tenant',
        fields: {
          name: 'Name',
          code: 'Code',
          namespace: 'App Namespace',
          url_production: 'Website URL Production',
          url_staging: 'Website URL Staging',
          url_development: 'Website URL Development',
          url_card: 'Website Card page base URL',
          email: 'Email of sender for user notifications',
          logo: 'Logo',
          color: 'Color',
          users_count: 'Users',
          archives_count: 'Archives',
          users: 'Users',
          archives: 'Archives'
        },
        select: `Select tenant`
      },
      locations: {
        title: 'Locations',
        subtitle: `Manage Cards' Locations`,
        single: 'Location',
        fields: {
          name: 'Name',
          address: 'Address',
          location: 'Location',
          accuracy: 'Accuracy',
          location_latitude: 'Latitude',
          location_longitude: 'Longitude',
          geometries_separator: 'GIS Geometries Fields',
          polygon: 'Polygon',
          pointz: 'PointZ',
          multipointz: 'MultiPointZ',
          polygonz: 'PolygonZ',
          linestringz: 'LineStringZ',
          geometries: 'Geometries',
          classification_id: 'Classification',
          card_id: 'Card'
        }
      },
      classifications: {
        title: 'Classifications',
        subtitle: `Manage Classifications`,
        single: 'Classification',
        fields: {
          name: 'Name',
          type: 'Type',
          color: 'Color',
          description: 'Description',
          location: 'Location',
          location_id: 'Location',
          locations_count: 'Locations'
        }
      },
      tags: {
        title: 'Tags',
        subtitle: 'Manage Tags',
        single: 'Tag',
        fields: {
          name: 'Name',
          cards_count: 'Cards'
        }
      },
      mapviewer: {
        title: 'Map Viewer',
        subtitle: 'Map Viewer for georeferenced raster images',
        single: 'Map Viewer'
      },
      medias: {
        title: 'Media',
        subtitle: 'Media manager',
        single: 'Media',
        fields: {
          file: 'File',
          original: 'Original',
          card: 'Card',
          code: 'Code',
          description: 'Description',
          format: 'Format',
          format_select: 'Select a format'
        },
      },
      logs: {
        title: 'Logs',
        subtitle: 'API Platform Logs',
        single: 'Log',
        fields: {
          causer: 'Causer',
          status: 'Status',
          event: 'Event',
          route: 'Route',
        }
      },
    }
  },
  it: {
    global: {
      menu: 'Menu',
      more: 'Scopri tutto...',
      back: 'Torna indietro',
      hello: 'Ciao, ',
      of: 'di',
      select: 'Seleziona...',
      no_results: 'Nessun risultato',
      search_everywhere: `Cerca su tutti i campi`,
      select_one: `Selezionare un'opzione`,
      type: 'Tipologia',
      legend: 'Legenda',
      hello_welcome: ' e benvenuto'
    },
    tips: {
      save_before: 'E\' necessario salvare prima',
      remember_update: 'Ricordati di salvare la scheda.',
      remember_save: 'La scheda verrà salvata in automatico.'
    },
    actions: {
      label: 'Azioni',
      new: 'Aggiungi',
      edit: 'Modifica',
      delete: 'Elimina',
      save: 'Salva',
      update: 'Aggiorna',
      cancel: 'Annulla',
      confirm: 'Conferma',
      signin: 'Accedi',
      import: 'Importa',
      import_media: 'Media',
      import_cards: 'Schede',
      upload_files: 'Carica media',
      file_fields: 'Campi del file',
      select: (ctx) => `Seleziona ${ctx.named('name')}`,
      export_cards: 'Esporta',
      export: 'Esporta',
      editor: {
        list: 'lista',
      },
      search: 'Cerca',
      select_one: 'Seleziona un valore',
      download_import_model: 'Scarica modello',
      export_shapefile: 'Esporta Shapefile',
      import_shapefile: `Importa Shapefile`,
      register: 'Registrati',
      password_reset: 'Reimposta password',
      resend_verification: 'Invia nuovamente verifica della email'
    },
    components: {
      crud: {
        card_owned_by: 'Scheda appartenente a',
        card_owner_info: 'Per informazioni e segnalazioni contattare i gestori del fondo',
        website_card_url: 'Mostra la scheda sul sito web'
      },
      lemmatizer: {
        associate: 'Associa',
        update: 'Aggiorna',
        new: 'Aggiungi nuovo',
        no_lemma: 'Nessuno associato',
        save_before: 'Prima salvare la scheda principale'
      },
      chart: {
        cards: 'Schede'
      },
      map: {
        want_add_location: 'Vuoi aggiungere delle coordinate per questo luogo?',
        add_location: 'Aggiungi coordinate',
        tip_double_click: 'Fai doppio click sulla mappa per spostare il marker o trascinalo nella posizione desiderata',
        place_coordinates: 'Coordinate in questo punto',
        popup_default: 'Questo punto definisce la posizione del luogo corrente.',
        remove_location: 'Rimuovi coordinate',
        no_data: 'Non ci sono dati geografici da mostrare'
      },
      geometry: {
        add_point: 'Aggiungi punto',
        create: 'Crea',
        remove: 'Rimuovi',
        want_create: (ctx) => `Vuoi creare una geometria ${ctx.named('type')} per questa scheda?`,
        last_point: `L'ultimo punto per chiudere il poligono deve coincidere col primo punto creato`,
        digitize: `Per digitalizzare una geometria clicca sulla barra degli strumenti in alto a destra selezionando uno dei tipi di geometrie disponibili. Clicca quindi sulla mappa per digitalizzare ogni vertice necessario. Clicca sul pulsante di modifica per modificare la posizione e l'aspetto della geometria. Clicca sul pulsante di eliminazione per eliminarla.`,
        centroid: `Centroide calcolato`
      },
      file_manager: {
        no_content: 'Nessun contenuto associato.',
        is_cover: 'Immagine principale',
        set_as_cover: 'Imposta come principale'
      },
      relatizer: {
        add: 'Aggiungi',
        update: 'Aggiorna',
        new: 'Aggiungi una nuovo correlato',
        no_related: 'Nessuna scheda associata',
        save_before: 'Prima di procedere aggiungere la correlazione'
      },
      threed_visualizer: {
        scene_elements: 'Elementi 3D',
      }
    },
    modals: {
      delete: {
        title: `Vuoi procedere con l'eliminazione della scheda?`,
        content: `L'operazione una volta eseguita non può essere annullata`,
        confirmed: (ctx) => `${ctx.named('name')} eliminato`,
        title_named: (ctx) => `Vuoi procedere con l'eliminazione di ${ctx.named('name')}`,
      },
      update: {
        confirmed: 'Aggiornato!'
      },
      save: {
        confirmed: 'Salvato!'
      },
      download: {
        confirmed: 'Scaricato!'
      },
      importer: {
        title: (ctx) => `Importazione dei dati di ${ctx.named('name')} tramite file`,
        media_title: (ctx) => `Importazione dei media per ${ctx.named('name')}`,
        content: `Carica un file dal tuo computer trascinandolo qui sotto e poi clicca sul bottone di upload. Poi clicca sul pulsante "Importa" per importare i dati.`,
        media_content: `Carica i media dal tuo computer trascinandolo qui sotto e poi clicca sul bottone di upload. Poi clicca sul pulsante "Importa" per importare i files e associarli alle schede in automatico.<br/>Per associarli in automatico i file devono avere come nome il "codice" della scheda.`,
        confirmed: `Procedura di importazione avviata. Potrebbe durare qualche minuto a seconda la dimensione del file.`,
        warnings: (ctx) => `<strong>NOTA</strong>: Possono essere caricati al massimo <strong>n. ${ctx.named('max_file_uploads')} files</strong> alla volta, per un peso massimo di <strong>${ctx.named('post_max_size')}B</strong>.`,
        uploaded: `File caricati con successo e pronti per l'elaborazione.`,
        upload_only: `Carica solo: `,
        type_not_allowed: 'Questo tipo di file non è supportato',
        single_file_fields: `<strong>NOTA</strong>: I campi del file vengono applicati a tutti i file scelti per l'importazione`,
        shapefile_title: `Importazione Shapefile`,
        shapefile_content: `Importa dati GIS vettoriali dal formato Shapefile`,
      },
      exporter: {
        title: (ctx) => `Esportazione dei dati di ${ctx.named('name')}`,
        content: `Esporta le schede nel formato Excel o PDF. Per esportare una parte di schede, applicare prima i filtri e poi riaprire la modale di esportazione.`,
        confirmed: `Procedura di esportazione avviata. Potrebbe durare qualche minuto a seconda la dimensione del file. Verrà inviata una mail all'utente con l'allegato richiesto.`,
        shapefile_content: `Exporta le geometrie in ESRI Shapefile. Seleziona un tipo di geometria e una categoria di dati. Poi clicca su Esporta.`,
      },
      coverize: {
        confirmed: 'Immagine impostata come principale.'
      },
      confirm: {
        title: 'Conferma',
        content: `Vuoi confermare l'operazione?`
      },
      clone: {
        title: 'Duplicazione scheda',
        content: 'Vuoi effettuare una copia di questa scheda?',
        confirmed: 'Scheda clonata con successo'
      },
      error: {
        import: 'Importazione fallita',
        upload: 'Caricamento dei files fallito',
        upload_file_type: 'Formato file non riconosciuto',
        missing_archive: 'Archivio/Fondo mancante',
        related: 'Errore durante la creazione di una scheda associata',
        missing_category: 'Per favore selezionare una categoria',
        missing_geometry: 'Per favore selezionare una geometria',
        lemma_attach: `Si è verificato un errore durante l'operazione di associazione`,
        lemma_update: `Si è verificato un errore durante l'operazione di aggiornamento dell'associazione`,
        export: `Si è verificato un errore durante l'esportazione del file`,
        user_registration: `Si è verificato un errore durante la creazione dell'utente`,
      }
    },
    menu: {
      login: 'Accedi',
      logout: 'Esci',
      register: 'Registrati',
      home: 'Dashboard',
      projects: 'Progetti',
      users: 'Utenti',
      cards: 'Schede',
      archives: 'Fondi',
      locations: 'Luoghi',
      classifications: 'Classificazioni',
      tags: 'Tag',
      mapviewer: 'Mappa',
      profile: 'Il mio profilo',
      medias: 'Media',
      logs: 'Log',
      tenants: 'Tenant'
    },
    pagination: {
      results: 'Risultati',
      prev: 'Precedente',
      next: 'Successiva',
      last: 'Ultima pagina'
    },
    profile: {
      confirmed: 'Confermato'
    },
    roles: {
      admin: 'Amministratore',
      archive_manager: 'Gestore Fondi',
      card_manager: 'Compilatore Schede',
      tenant_manager: 'Gestore Tenant',
      viewer: 'Visualizzatore'
    },
    home: {
      tips: 'Suggerimenti!',
    },
    views: {
      dashboard: {
        title: 'Benvenuto!',
        subtitle: 'Dashboard',
        single: '',
        fields: {}
      },
      unauthorized: {
        title: 'Non puoi accedere a questa pagina',
        subtitle: 'Non hai i permessi per accedere a questa sezione',
        single: '',
        fields: {}
      },
      login: {
        title: 'Accedi',
        subtitle: 'Inserisci le tue credenziali',
        single: '',
        fields: {
          name: 'Nome',
          email: 'Email',
          password: 'Password',
          confirm_password: 'Ripeti password',
          institution: 'Ente'
        },
        missing_email: 'Inserisci la tua email',
        reset_password: `E' stata inviata una email con un link che ti consente di reimpostare la tua password.`
      },
      register: {
        title: 'Registrazione',
        lost_password: 'Password dimenticata',
        request:'La richiesta di registrazione è avvenuta con successo.<br/>Riceverai una mail con un link per verificare il tuo account. Clicca sul link presente per poter accedere alla piattaforma.',
        resend_verification: `E' stata inviata una nuova mail di verifica all'indirizzo email indicato.`,
        email_already_verified: 'La tua email è stata già verificata. Clicca sul seguente pulsante per effettuare il login',
        email_verified: 'La tua email è stata verificata. Clicca sul seguente pulsante per effettuare il login.',
        email_to_verify: 'La tua email deve essere verificata.'
      },
      lostpassword: {
        title: 'Reimposta la password',
        missing_data: 'Per reimpostare la password vai alla pagina di login e clicca su "Password dimenticata"',
        password_changed: 'La password è stata aggiornata con successo. Adesso puoi effettuare login.'
      },
      places: {
        title: 'Places',
        single: 'Place',
        fields: {
          name: 'Name',
          area: 'Area',
        }
      },
      placenames: {
        title: 'Placenames',
        single: 'Placename',
        fields: {
          name: 'Name',
          latin_transcript: 'Latin transcript',
          literal_transcript: 'Literal transcript',
          region: 'Region'
        }
      },
      reference_sources: {
        title: 'Sources',
        single: 'Source',
        fields: {
          name: 'Name',
        }
      },
      references: {
        title: 'Bibliography',
        single: 'Reference',
        fields: {
          citation: 'Citation',
        }
      },
    }
  }
}

// All messages of plugins is like: $t('plugins.PLUGIN_NAME.*')
for(const locale in messages){
  messages[locale].plugins = []
  for(const plugin in plugins.messages){
    messages[locale].plugins[plugin] = plugins.messages[plugin][locale]
  }
}

// Create VueI18n instance with options
export default new VueI18n({
  locale: 'it', // set locale
  messages, // set locale messages
})