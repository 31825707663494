import Vue from 'vue'
import Router from 'vue-router'

// import store from '@/store'
import plugins from '@/plugins'

Vue.use(Router)

const defaultRoutes = [
  // Touchscreen Views
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/touch/Home.vue'),
  },
  {
    path: '/video-gallery',
    name: 'video-gallery',
    component: () => import('@/views/touch/VideoGallery.vue'),
  },
  {
    path: '/archive',
    name: 'archive',
    component: () => import('@/views/touch/Archive.vue'),
  },
  {
    path: '/explore-3d',
    name: 'explore-3d',
    component: () => import('@/views/touch/ThreeDGallery.vue'),
  },
  {
    path: '/virtual-tours',
    name: 'virtual-tours',
    component: () => import('@/views/touch/VirtualTours.vue'),
  },
  {
    path: '/drone-gallery',
    name: 'drone-gallery',
    component: () => import('@/views/touch/DroneGallery.vue'),
  },
  {
    path: '/webmap',
    name: 'webmap',
    component: () => import('@/views/touch/WebMap.vue'),
  },
  {
    path: '/ar',
    name: 'ar',
    component: () => import('@/views/touch/ARView.vue'),
  },
  {
    path: '/card/:id',
    name: 'card',
    component: () => import('@/views/touch/SingleCard.vue'),
  },
  {
    path: '/flipbooks-gallery',
    name: 'flipbooks-gallery',
    component: () => import('@/views/touch/FlipbooksGallery.vue'),
  },
  // {
  //   path: '/ar-test',
  //   name: 'ar-test',
  //   component: () => import('@/views/touch/ARToolkitView.vue'),
  // },

  // Default Views
  /*
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import('@/views/Unauthorized.vue'),
  },
  
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Register.vue')
  },
  {
    path: '/verify-email',
    name: 'verifyemail',
    component: () => import('@/views/VerifyEmail.vue')
  },
  {
    path: '/reset-password',
    name: 'resetpassword',
    component: () => import('@/views/ResetPassword.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/Profile.vue'),
  },
  // projects routes
  {
    path: '/projects',
    name: 'projects',
    component: () => import('@/views/Projects.vue')
  },
  {
    path: '/projects/edit/:id',
    name: 'project.edit',
    component: () => import('@/views/Project.vue'),
    props: { mode: 'edit', }
  },
  {
    path: '/projects/new',
    name: 'project.new',
    component: () => import('@/views/Project.vue'),
    props: { mode: 'new', }
  },

  // users routes
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/Users.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },
  {
    path: '/users/edit/:id',
    name: 'user.edit',
    component: () => import('@/views/User.vue'),
    props: { mode: 'edit', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },
  {
    path: '/users/new',
    name: 'user.new',
    component: () => import('@/views/User.vue'),
    props: { mode: 'new', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },

  // cards routes
  {
    path: '/cards',
    name: 'cards',
    component: () => import('@/views/Cards.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },
  {
    path: '/cards/show/:id',
    name: 'card.show',
    component: () => import('@/views/Card.vue'),
    props: { mode: 'show', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },
  {
    path: '/cards/edit/:id',
    name: 'card.edit',
    component: () => import('@/views/Card.vue'),
    props: { mode: 'edit', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },
  {
    path: '/cards/new',
    name: 'card.new',
    component: () => import('@/views/Card.vue'),
    props: { mode: 'new', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },

  // archives routes
  {
    path: '/archives',
    name: 'archives',
    component: () => import('@/views/Archives.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },
  {
    path: '/archives/edit/:id',
    name: 'archive.edit',
    component: () => import('@/views/Archive.vue'),
    props: { mode: 'edit', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },
  {
    path: '/archives/new',
    name: 'archive.new',
    component: () => import('@/views/Archive.vue'),
    props: { mode: 'new', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },

  // tenants routes
  {
    path: '/tenants',
    name: 'tenants',
    component: () => import('@/views/Tenants.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_tenants')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },
  {
    path: '/tenants/edit/:id',
    name: 'tenant.edit',
    component: () => import('@/views/Tenant.vue'),
    props: { mode: 'edit', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_tenants')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },
  {
    path: '/tenants/new',
    name: 'tenant.new',
    component: () => import('@/views/Tenant.vue'),
    props: { mode: 'new', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_tenants')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },

  // Locations routes
  {
    path: '/locations',
    name: 'locations',
    component: () => import('@/views/Locations.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_archives') || user.permissions.includes('manage_cards')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },
  {
    path: '/locations/show/:id',
    name: 'location.show',
    component: () => import('@/views/Location.vue'),
    props: { mode: 'show', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_archives') || user.permissions.includes('manage_cards')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },
  {
    path: '/locations/edit/:id',
    name: 'location.edit',
    component: () => import('@/views/Location.vue'),
    props: { mode: 'edit', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_archives') || user.permissions.includes('manage_cards')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },
  {
    path: '/locations/new',
    name: 'location.new',
    component: () => import('@/views/Location.vue'),
    props: { mode: 'new', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_archives') || user.permissions.includes('manage_cards')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },

  // Classifications routes
  {
    path: '/classifications',
    name: 'classifications',
    component: () => import('@/views/Classifications.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_archives') || user.permissions.includes('manage_cards')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },
  {
    path: '/classifications/show/:id',
    name: 'classification.show',
    component: () => import('@/views/Classification.vue'),
    props: { mode: 'show', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_archives') || user.permissions.includes('manage_cards')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },
  {
    path: '/classifications/edit/:id',
    name: 'classification.edit',
    component: () => import('@/views/Classification.vue'),
    props: { mode: 'edit', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_archives') || user.permissions.includes('manage_cards')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },
  {
    path: '/classifications/new',
    name: 'classification.new',
    component: () => import('@/views/Classification.vue'),
    props: { mode: 'new', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_archives') || user.permissions.includes('manage_cards')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },

  // Tags routes
  {
    path: '/tags',
    name: 'tags',
    component: () => import('@/views/Tags.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_archives') || user.permissions.includes('manage_cards')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },
  {
    path: '/tags/show/:id',
    name: 'tag.show',
    component: () => import('@/views/Tag.vue'),
    props: { mode: 'show', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_archives') || user.permissions.includes('manage_cards')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },
  {
    path: '/tags/edit/:id',
    name: 'tag.edit',
    component: () => import('@/views/Tag.vue'),
    props: { mode: 'edit', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_archives') || user.permissions.includes('manage_cards')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },
  {
    path: '/tags/new',
    name: 'tag.new',
    component: () => import('@/views/Tag.vue'),
    props: { mode: 'new', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_archives') || user.permissions.includes('manage_cards')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },

  // Medias routes
  {
    path: '/medias',
    name: 'medias',
    component: () => import('@/views/Medias.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },

  // Map viewer
  // {
  //   path: '/mapviewer',
  //   name: 'mapviewer',
  //   component: () => import('@/views/MapViewer.vue'),
  //   props: { mode: 'edit', }
  // },

  // Logs routes
  {
    path: '/logs',
    name: 'logs',
    component: () => import('@/views/Logs.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'home'})
      }
    }
  },
  */
]

export default new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE_PATH || '/',
  linkActiveClass: "",
  routes: [...defaultRoutes, ...plugins.routes]
})
