import axios from 'axios'
import appConfig from '@/config'
import store from '@/store'

const api = axios.create({
  baseURL: appConfig.apiRoot,
  headers: {
    'Content-Type': 'application/json',
    'X-App-From': process.env.VUE_APP_TENANT,
    'X-App-Name': process.env.VUE_APP_NAME
  },
})

api.interceptors.request.use(
  (config) => {
    // config.headers['X-Requested-With'] = 'XMLHttpRequest'
    config.headers['X-Localization'] = store.getters['app/locale'] || 'en'
    return config
  },
  (error) => Promise.reject(error)
)

export default {
  constructor(){},
  // metodo get per recuperare i modelli (se aggiungo id recupera il singolo oggetto)
  async login(user){
    return await api.post('login', user).then(response => {
      store.dispatch(`auth/login`, {data: response.data.data})
      return response.data
    }).catch(function(error){
      return { success: false, data: error.response.data.message }
    })
  },
  async logout(){
    store.dispatch(`auth/logout`)
    return { error: false }
  }
}