<script>
import api from '@/services/api'
import auth from '@/services/auth'
import _ from 'lodash'

export default {
  name: 'Api',
  props: {
    params: {
      type: Object,
      default: () => {}
    },
    orderBy: {
      type: String,
      default: ''
    },
    orderAsc: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      entities: null,
      isSingle: false,
      config: {
        'headers': {
          'Accept': 'application/json'
        }
      },
      pagination: {
        from: 0,
        to: 0,
        total: 0,
        per_page: 20,
        current_page: 0,
        last_page: 1
      },
      search: {},
      error: null,
      isLoading: false,
      filters: {},
      locales: {}
    }
  },
  watch: {
    filters: {
      handler: function() { this.fetch(this.model) },
      deep: true
    }
  },
  async created() {
    const vm = this
    vm.loading = true
    if(!vm.isAuthenticated){
      await vm.login()
    }
  },
  methods: {
    async fetch(model, id = null, page = 1, per_page = 20, laterFilters = {}) {
      const vm = this
      vm.isLoading = true
      await api.fetch(model || vm.model, id, {
        params: this.getParams(_.extend({ page: page, per_page: per_page }, laterFilters))
      }).then(response => {
        if(!response.success) vm.handleError(response.data)
        vm.entities = response.data
        vm.pagination = _.pick(response, _.keys(vm.pagination))
        vm.isLoading = false
      }).catch(function (error) {
        console.log('[catch error]', error)
        return {success: false, data: error}
      })
      // populate data with translation (e.g.: name_en, name_it, ...)
      // if(vm.data.translations){
      //   let t_fields = _.keys(vm.data.translations[0]) // get fields
      //   vm.data.translations.forEach(function (el) {
      //     t_fields.forEach(function(f){
      //       vm.data[f+'_'+el.locale] = el[f]
      //     })
      //   });
      // }
    },
    sort(field) {
      if (field == this.orderBy) {
        this.orderAsc = !this.orderAsc
      } else {
        this.orderBy = field
        this.orderAsc = true
      }
      this.fetch(this.model, null, this.pagination.current_page, this.pagination.per_page)
    },
    filterSearch(model, searchform) {
      this.search = searchform
      this.filters = searchform
      this.fetch(model)
    },
    async delete(model, id){
      // TODO: ritornare messaggio di conferma/errore
      let destroyed = await api.delete(this.model, id)
      return !!destroyed
    },
    async batch(mode, model, params){
      return await api.batch(mode, this.model, params)
    },
    async post(model, params = []){
      return await api.create(model, params)
    },
    /*store() {
      if (!this.data) return console.log('No valid data')
      if (this.data.id) this.update()
      const vm = this
      vm.isLoading = true
      console.log(this.data)
      return axios.post(vm.url, this.data, this.config).then(response => {
        vm.isLoading = false
        return response
      })
    },
    update(data = null) {
      data = data || this.data
      if (!data || !this.data.id) return console.log('No valid data')
      const vm = this
      vm.isLoading = true
      return axios.put(vm.url, data, this.config).then(response => {
        //console.log('updating...',response.data)
        if (response) vm.data = response.data.data || response.data
        vm.isLoading = false
      })
    },
    destroy(url = null) {
      if (!this.data || !this.data.id || !url) console.log('No valid data');
      const vm = this
      vm.isLoading = true
      if(!url) url = vm.url 
      return axios.delete(url, this.config).then(() => {
        vm.isLoading = false
      })
    },
    exportData(format) {
      const params = _.map(this.getParams({ page: 1 }), (val, key) => {
        return key+'='+val
      })
      console.log(_.join(params, '&'))
      window.open(format.url+'?'+_.join(params, '&'), '_blank')
    },*/
    getParams(filters = {}) {
      return _.extend(this.params, {
          order_by: this.orderBy,
          order: (this.orderAsc) ? 'asc' : 'desc',
          search: this.search.full_text || null
        }, this.filters, filters)
    },
    getLocales() {
      const vm = this
      vm.isLoading = true
      // axios.get('/api/locales', {
      //   //params: this.getParams(_.extend({ page: page }, laterFilters))
      // }, this.config).then(response => {
      //   //console.log('populate data...',response.data)
      //   vm.locales = response.data.data || response.data
      // }).catch(e => {
      //   vm.error = e.data || e
      //   console.log(e)
      // }).then(() => {
      //   vm.isLoading = false
      // })
    },
    /** 
      * Simple Get Call 
    */
    async get(url, params = []) {
      const vm = this
      vm.isLoading = true
      return await api.fetch(url, null, {params: params}).then(response => {
        vm.isLoading = false
        return response
      }).catch(function (error) {
        console.log('[catch error]', error)
        return {success: false, data: error}
      });
    },
    handleError(error){
      console.log('[handleError]', error)
      switch(error){
        case 'unauthorized': this.login(); break;
      }
    },
    async login(){
      const vm = this
      try {
        if(process.env.VUE_APP_API_USERNAME && process.env.VUE_APP_API_PASSWORD){
          await auth.login({
            email: process.env.VUE_APP_API_USERNAME, 
            password: process.env.VUE_APP_API_PASSWORD 
          }).then(response => {
            console.log(response)
            if(response.success) {
              vm.$router.go() // reload page after login
            }else{
              vm.error = response.message || response.data
              vm.status = response.data.status || null
              vm.showError = true
            }
          })
        } else {
          alert('NO API CREDENTIALS')
        }
      } catch (error){
        vm.error = error
        vm.showError = true
        vm.submitting = false
      }
    }
  }
}
</script>
