
const state = {
  locale: null,
  showUserMenu: false
}

const getters = {
  locale: state => state.locale,
  showUserMenu: state => state.showUserMenu
}

const actions = {
  switchLocale({commit}, { locale }) {
    commit('setLocale', locale)
  },
  showUserMenu({commit}, { showUserMenu }) {
    commit('setUserMenu', showUserMenu)
  }
}

const mutations = {
  setLocale(state, locale){
    state.locale = locale
  },
  setUserMenu(state, showUserMenu){
    state.showUserMenu = showUserMenu
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}