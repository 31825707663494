
const state = {
  token: null,
  user: null
}

const getters = {
  isAuthenticated: state => !!state.token || !!state.user,
  user: (state) => Object.assign({}, state.user),
  token: state => state.token
}

const actions = {
  login({commit}, { data }) {
    commit('setUser', data)
  },
  logout({commit}){
    commit('logout', null)
  }
}

const mutations = {
  setUser(state, data){
    state.token = data.access_token
    state.user = data.user
  },
  logout(state){
    state.token = null
    state.user = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}