import Vuex from 'vuex'
import Vue from 'vue'
import createPersistedState from 'vuex-persistedstate'
import app from './modules/app'
import auth from './modules/auth'

// Load Vuex
Vue.use(Vuex)

// Create store
export default new Vuex.Store({
  modules: {
    app,
    auth
  },
  plugins: [createPersistedState()]
})