module.exports = {
  host: process.env.HOST || 'localhost',
  open: true,
  port: process.env.PORT || 8081,
  apiRoot: process.env.VUE_APP_API_ROOT || 'http://localhost:8001',
  menuItems: [
    {route: '/home',                permission: 'all',           label: 'menu.home', icon: 'chart-pie'},
    {route: '/locations',           permission: 'manage_cards',  label: 'menu.locations', icon: 'map-marker-alt'},
    {route: '/classifications',     permission: 'manage_cards',  label: 'menu.classifications', icon: 'palette'},
    {route: '/tags',                permission: 'manage_cards',  label: 'menu.tags', icon: 'tags'},
    {route: '/cards',               permission: 'manage_users',  label: 'menu.cards', icon: 'file-alt'},
    {route: '/medias',              permission: 'manage_users',  label: 'menu.medias', icon: 'photo-video'},
    {route: '/archives',            permission: 'manage_users',  label: 'menu.archives', icon: 'archive'},
    {route: '/users',               permission: 'manage_users',  label: 'menu.users', icon: 'users'},
    {route: '/tenants',             permission: 'manage_tenants',  label: 'menu.tenants', icon: 'globe'},
    {route: '/logs',                permission: 'manage_users',  label: 'menu.logs', icon: 'exclamation-triangle'},
  ]
}