<template>
  <multiselect 
    v-model="selected" 
    :options="options"
    :multiple="true"
    :placeholder="selectPlaceholder"
    :label="label"
    :track-by="label"
    @input="emit"
    :max-height="150"
    :close-on-select="true"
    :class="classes"
    :disabled="disable"
    @search-change="onSearch"
    :taggable="taggable"
    @tag="addTag">
  </multiselect>
</template>
<script>
  import _ from 'lodash'
  import api from '@/services/api'

  import Multiselect from 'vue-multiselect/src/Multiselect.vue'
  
  export default {
    components: { Multiselect },
    props: {
      label: {
        type: [String, Array],
        default: null
      },
      valueField: {
        type: String,
        default: 'id'
      },
      value: {
        required: true,
      },
      model: {
        type: String,
        required: true
      },
      searchField: {
        type: String,
        default: 'search'
      },
      delay: {
        type: Number,
        default: 500
      },
      placeholder: {
        type: String
      },
      taggable: {
        type: Boolean,
        default: false
      },
      classes: {
        default: null
      },
      disable: {
        default: false
      },
      icon: {
        default: false
      },
      iconName: {
        default: null
      },
      init: {
        type: Boolean,
        default: true
      },
    },
    data() {
      return {
        options: [],
        selected: null,
        disabled: false
      }
    },
    computed: {
      selectPlaceholder(){
        return this.placeholder || this.$t('actions.select_one')
      }
    },
    created() {
      const vm = this
      if (vm.value) vm.selected = _.clone(vm.value)
      vm.updateOptions = _.debounce(vm.getOptions, vm.delay)
      if(vm.init) vm.getOptions('', null, vm)
      vm.disabled = vm.disable
    },
    methods: {
      onSearch: function(search, loading) {
        this.updateOptions(search, loading, this)
      },
      updateOptions: () => {},
      async getOptions(search, loading, vm) {
        const params = {}
        params[vm.searchField] = search
        params['type'] = 'associations' // in order to not fail access route
        // console.log('[getOptions] for ...', search)
        await api.fetch(vm.model, null, {'params': params}).then((result) => {
          vm.options = result.data || result
        })
      },
      emit: function(val = null) {
        const vm = this
        if (!val) {
          vm.subLabelText = null
          this.$emit('input', null)
          this.$emit('select', null)
        } else {
          const newVal = (val[this.valueField]) ? val[this.valueField] : val
          this.$emit('input', newVal)
          this.$emit('select', val)
          this.$emit('selected', vm.selected)
        }
      },
      extension(file){
        let re = /(?:\.([^.]+))?$/
        return re.exec(file)[1]
      },
      iconCode(file){
        let ext = this.extension(file)
        switch(ext){
          case 'jpg':
          case 'jpeg':
          case 'png':
          case 'tif':
            return 'image'
          case 'obj':   
            return 'cube'
          case 'xls':
          case 'xlsx':
          case 'csv':
            return 'file-excel'
          default:      
            return 'file'
        }
      },
      async addTag(newTag) {
        const vm = this
        let params = {}
        params[vm.label] = newTag
        await api.create(vm.model, params).then((response) => {
          if(response.success) {
            vm.options.push(response.data)
            vm.selected.push(response.data)
            vm.value.push(response.data)
          }
        })
      }
    }
  }
</script>
<style lang="less">
@tailwind base;
@tailwind components;
@tailwind utilities;
.multiselect {
  @apply border;
  .multiselect__single,
  input#activitySel.multiselect__input,
  input#locationSel,
  .multiselect__tags {
    @apply border-none p-4 pb-2;
  }
  .multiselect--active {
    @apply border-none;
  }
  .multiselect__select {
    top: 12px;
  }
  /*.multiselect__option--highlight,
  .multiselect__option--highlight::after {}*/
}
</style>
